.main_container_login {
    background-color: rgb(98,89,202); 
    width: 100%; 
    height: 100vh; 
    position: relative;
}

.main_form_login {
    height: 100vh; 
    width: 100%; 
    display: flex;
    position: absolute;
    left: 0px;
    flex-direction: column
}

.container_text_border_line {
    width: 100%; 
    text-align: center; 
    border-bottom: 2px solid rgb(98,89,202); 
    line-height: 0.1em;
    margin: 10px 0 20px; 
}

.line_around_text {
    background: #fff; 
    padding: 0 10px;
    font-size: 14
}

.space_between_form {
    height: 45px;
}

.space_after_form {
    height: 70px;
}

.container_logo {
    height: 28vh; 
    display: flex; 
    justify-content: center; 
    align-items: center
}

.logo_dimensions{
    max-width: 45%;
    height: auto;
}


.right_background_image {
    height: 100vh; 
    width: 100%;
    position: absolute; 
    right: 0;
    object-fit: cover;
}

.frase_final {
    text-align: 'center';
    margin-top: 20px
}

/* Aplica los estilos solo si la pantalla tiene de 600px o menos */
@media only screen and (max-width: 600px) {

      .space_between_form {
          height: 15px;
      }


      .space_after_form {
         height: 18px;
      }


      .logo_dimensions{
        height: 13%;
        width: 45%;
    }

    .frase_final{
        text-align: 'center';
        margin-top: 5px
    }

    .main_container_login {
        width: 100%;
        height: 100%;
        margin: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }
}